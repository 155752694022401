<template>
  <div class="col-12 col-lg-6 c g">
    <div class="col-12 text-center g">
        <h4 class="text-muted">
            {{ info.teacher_name }}
        </h4>
    </div>
    <div class="card">
        <div class="col-12 text-center" v-if="loading">
            <br><br>
            <img :src="require('@/assets/images/loading.svg')" style="width: 100px" alt="">
            <br>
            جاري التحميل...
        </div>
        <template v-if="!loading">
            <div class="card-header">
                <span>
                    {{ info.title }}
                </span>
                <span>{{ info.date }}</span>
            </div>
            <div class="card-body" v-if="fatra == ''">
                <div class="row">
                    <div class="col-12 col-lg-6 g">
                        <button class="btn btn-lg btn-block btn-primary" @click="fatra = '1'">
                            الفترة الأولى
                        </button>
                    </div>
                    <div class="col-12 col-lg-6 g">
                        <button class="btn btn-lg btn-block btn-success" @click="fatra = '2'">
                            الفترة الثانية
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body" v-if="fatra != ''">
                <div class="col-12 text-center">
                    <strong><i class="fa fa-hand-pointer-o"></i> اضغط على الحالة لتغييرها.</strong>
                    <br>
                    <small>يمكنك حفظ الغياب في اسفل الصفحة.</small>
                </div>
                <div class="col-12 table-responsive g">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>
                                الحالة
                            </th>
                            <th>
                                الطالب 
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="student in info.comm.students" :key="student._id" :class="students[student.number] == '1' ? 'bg-light-success' : 'bg-light-danger'">
                                <td>
                                    <button class="btn btn-sm btn-success btn-block" style="border-radius: 2px" v-if="students[student.number] == '1'" @click="students[student.number] = '3'; students = JSON.parse(JSON.stringify(students))">
                                        حاضر
                                    </button>
                                    <button class="btn btn-sm btn-danger btn-block" style="border-radius: 2px" v-if="students[student.number] == '3'" @click="students[student.number] = '1'; students = JSON.parse(JSON.stringify(students))">
                                        غائب
                                    </button>
                                </td>
                                <td>
                                    <strong class="text-dark">{{ student.name }}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-12 text-center g"><br>
                        <button class="btn btn-primary btn-block" @click="done()">
                            <i class="fa fa-save"></i>
                            حفظ الغياب الآن
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </div>
    <div class="col-12 text-center g">
        <small><a href="https://tahdir.net">نظام التحضير الذكي</a></small>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            info: {},
            students: {},
            fatra: ""
        }
    },
    created(){
        var g = this;
        $.post(api + "/teacher/comm-attend", {
            username: window.location.href.split("/")[4],
            password: window.location.href.split("/")[5],
            id: window.location.href.split("/")[6],
            date: window.location.href.split("/")[7],
            title: decodeURI(window.location.href.split("/")[8]),
            fatra: g.fatra
        })
        .then(function(r){
            if(r.status == 200){
                alert(r.response, 200)
            }else{
                //
                g.info = r.response;
                g.info.comm.students.forEach(function(a){
                    g.students[a.number] = "1";
                })
            }
            g.loading = false;
        })
        .fail(function(r){
            g.loading = false;
            alert(`حدث خطأ في الاتصال`, 200)
        })
    },
    methods: {
        done(){
            var g = this;
            $.post(api + "/teacher/comm-attend/done", {
                username: window.location.href.split("/")[4],
                password: window.location.href.split("/")[5],
                id: window.location.href.split("/")[6],
                date: window.location.href.split("/")[7],
                title: decodeURI(window.location.href.split("/")[8]),
                students: JSON.stringify(g.students),
                fatra: g.fatra
            })
            .then(function(r){
                if(r.status == 200){
                    alert(r.response, 200)
                }else{
                    //
                    alert("تم الحفظ بنجاح", 100)
                }
                g.loading = false;
            })
            .fail(function(r){
                g.loading = false;
                alert(`حدث خطأ في الاتصال`, 200)
            })
        }
    }
}
</script>

<style>

</style>